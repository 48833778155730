<template>
  <div class="home" style="overflow:hidden">
    <div v-if="isLoading">
      <LoadingHome></LoadingHome>
    </div>
    <div v-else>
      <!-- 大電視-桌機 -->
      <div class="big-banner d-none d-md-block">
        <swiper
        :pagination="true"
        :loop="true"
        :autoplay="{
        delay: 5000,
        disableOnInteraction: false
        }"
        :spaceBetween="0"
        :initialSlide="0"
        class="mySwiper h-100">
          <swiper-slide v-for="(item,index) in banner" :key="index+'A'">
            <a :href="item.targetUrl">
              <img class="w-100" :src="item.image" :alt="item.name">
            </a>
          </swiper-slide>
        </swiper>
      </div>
      <!-- 大電視-手機 -->
      <div class="big-banner d-md-none">
        <swiper
        :pagination="true"
        :loop="true"
        :autoplay="{
        delay: 5000,
        disableOnInteraction: false
        }"
        :spaceBetween="0"
        :initialSlide="0"
        class="mySwiper h-100">
          <swiper-slide v-for="(item,index) in banner_m" :key="index+'B'">
            <a :href="item.targetUrl">
              <img class="w-100" :src="item.image" :alt="item.name">
            </a>
          </swiper-slide>
        </swiper>
      </div>
      <div class="container">
        <!-- 首頁影片 -->
        <div class="row justify-content-center mt-4 mt-lg-5" v-if="video">
          <div class="col-12 col-lg-10">
            <!-- YT 嵌入 -->
            <div v-if="video.src==='yt'" class="ratio ratio-16x9">
              <iframe class="w-100" :src="video.url" autoplay="autoplay" frameborder="0"></iframe>
            </div>
            <!--  mp4 播放 -->
            <div v-if="video.src==='aws'" class="ratio ratio-16x9">
              <video id="vid" class="w-100" loop="true" controls muted playsinline>
                <source :src="`${video.url}#t=0.001`" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="d-flex justify-content-center mt-4">
            <h4 class="fs-10 fs-sm-3 fs-md-2 fs-lg-4 mx-auto mt-4 mb-4 mb-lg-2 mt-lg-5 pb-2 text-dark text-center fw-medium fw-lg-light border-bottom border-dark border-2" style="width:fit-content;">聯邦租車優惠活動</h4>
          </div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-10">
                <div class="row pb-2 justify-content-center pt-4">
                  <div class="col-md-12 mb-md-3">
                    <div class="row gx-4 gx-md-3 gy-5 mb-md-5">
                      <div
                        class="col-md-3 col-6 position-relative"
                        v-for="(item, index) in productItem"
                        :key="index"
                      >
                        <div class="card p-0 card-hover position-relative">
                          <router-link
                            :to="`/product/${item.Id}`"
                            class="text-secondary"
                          >
                            <div class="imgContainer ratio ratio-1x1">
                              <img
                                :src="item.Image"
                                class="card-img-top border-0"
                                alt="product image"
                              />
                            </div>
                            <!-- 已售完 -->
                            <div
                              class="position-absolute start-0 top-0 opacity-75 sold_out"
                              v-if="item.RealStock === 0"
                            >
                              <div
                                class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                              >
                                已售完
                              </div>
                            </div>
                            <div
                              class="position-absolute start-0 top-0 opacity-75 sold_out"
                              v-else-if="item.DisplayStock === 0"
                            >
                              <div
                                class="contain position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center"
                              >
                                售完補貨中
                              </div>
                            </div>
                            <div class="card-body px-3 pb-0">
                              <div class="d-flex">
                                <p class="d-inline-block text-white bg-primary px-2" style="font-size: 14px; text-align: center; width: 117px; overflow: hidden; height: 21px;">{{ item.Slogan }}</p>
                              </div>
                              <p
                                class="card-title card-title-height word-break fs-lg-10 fs-md-7 fs-10 p-1 text-break"
                              >
                                {{ item.Name }}
                              </p>
                              <div
                                style="display: flex; align-items: center; justify-content: start"
                              >
                                <small
                                  class="card-text fs-lg-6 fs-md-7 fs-7"
                                  style="text-decoration: line-through; width: 3.5rem"
                                  >${{ item.MSRP.toLocaleString() }}</small
                                >
                                <p
                                  class="text-nowrap text-danger mb-0 fw-bolder fs-lg-5 fs-md-6 fs-6 fs-xl-3"
                                >
                                  NT$ {{ item.Price.toLocaleString() }}
                                </p>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center mb-4">
          <div class="col-12 justify-content-center mt-2">
            <h4 class="fs-10 fs-sm-3 fs-md-2 fs-lg-4 mx-auto mt-4 mb-4 mb-lg-5 mt-lg-2 pb-2 text-dark text-center fw-medium fw-lg-light border-bottom border-dark border-2" style="width:fit-content;">最新消息</h4>
          </div>
          <div class="col-12 col-lg-10">
            <div class="row gy-3 justify-content-start">
              <div class="col-12 col-lg-6" v-for="item in activitybanner" :key="item.code">
                <a :href="item.targetUrl" class="w-100">
                  <img :src="item.image" alt="" class="w-100">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingHome from '../../views/Loading/LoadingHome.vue'
import ProductSvc from '@/service/product-service.js'
import BannerSvc from '@/service/banner-service.js'
import checkVideoSrc from '../../assets/js/checkVideoSrc'

export default {
  components: {
    LoadingHome
  },
  data () {
    return {
      banner: [],
      banner_m: [],
      video: {},
      activitybanner: [], // ? 最新消息
      productItem: [],
      isLoading: false, // ?進場動畫開關
      adModal: ''
    }
  },
  methods: {
    getProducts () {
      ProductSvc.GetUnionCarRentalList().then(list => {
        this.productItem = list
      })
    },
    getBanner () {
      this.isLoading = true
      BannerSvc.GetEventBanner('unionRentalCar_Banner')
        .then((SvcData) => {
          this.banner = SvcData
          this.isLoading = false
        })
      BannerSvc.GetEventBanner('unionRentalCar_m_Banner')
        .then((SvcData) => {
          this.banner_m = SvcData
        })
    },
    getVideo () {
      BannerSvc.GetEventBanner('unionCarRental_video')
        .then((SvcData) => {
          this.video = checkVideoSrc(SvcData[0].targetUrl)
        })
    },
    getActicityBanner () {
      // 最新消息
      BannerSvc.GetEventBanner('unionCarRental_Event')
        .then((SvcData) => {
          this.activitybanner = SvcData
        })
    },
    saveQueryToSessionStorage () {
      // (沒有query就帶4)
      const query = this.$route.query.store || 4
      sessionStorage.setItem('store', query)
    }
  },
  mounted () {
    const storedData = sessionStorage.getItem('store')
    if (!storedData) {
      this.saveQueryToSessionStorage() // 聯邦租車存入session
    }
    this.getProducts()
    this.getBanner()
    this.getVideo()
    this.getActicityBanner()
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.swiper-pagination-bullet){
  background-color: #cacbce;
  width: 10px;
  height: 10px;
  @media (min-width: 992px) {
    width: 12px;
    height: 12px;
  }
}
::v-deep(.swiper-pagination-bullet-active){
  background-color: #003894;
}
::v-deep(.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet){
  margin: 0 8px;
  @media (min-width: 992px) {
    margin: 0 12px;
  }
}
.big-banner{
  height: 34vw;
  @media (max-width:1500px) {
    height: 36vw;
  }
  @media (max-width:767px) {
    height: 100vw;
  }
  @media (max-width:500px) {
    height: 103vw;
  }
  img {
    height: 31vw;
    @media (max-width:767px) {
      height: 92vw;
    }
  }
}
.wrap{
  @media (max-width:430px) {
    display: block!important;
  }
}
.title-height{
  line-height: 1.5;
  overflow: hidden;
  @media (max-width: 992px) {
    height: 3.6rem;
  }
  @media (max-width: 767px) {
    height: 3.2rem;
  }
}
.adModal{
  .modal-content{
    width: auto;
    background-color: transparent;
    border: 0;
  }
  .closeEventIcon{
    width: 32px;
  }
}
</style>
